import { type NetworkQuality } from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { type MediaFormat } from '@lp-lib/game';

import spinner from '../../assets/img/loading-spinner.png';
import { useToggleBroadcastFeature } from '../../components/Broadcast';
import { type Option, Select } from '../../components/common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../../components/ConfirmCancelModalContext/ModalWrapper';
import { useExperienceScoreAPI } from '../../components/ExperienceScore';
import { useIsLiveGamePlay } from '../../components/Game/hooks';
import { reset } from '../../components/Game/store';
import DupHostStreamView from '../../components/Host/DupHostStreamView';
import { ArrowDownIcon, ArrowUpIcon } from '../../components/icons/Arrows';
import { PlayIcon } from '../../components/icons/PlayIcon';
import { LayoutAnchor } from '../../components/LayoutAnchors/LayoutAnchors';
import { Loading } from '../../components/Loading';
import { useLobbyAPI } from '../../components/Lobby';
import { useMusicPlayerContext } from '../../components/MusicPlayer/Context';
import { useNumOfParticipants } from '../../components/Player';
import {
  useIsStreamSessionAborted,
  useIsStreamSessionAlive,
  useIsStreamSessionInited,
  useIsStreamStartable,
  useStreamSessionControlAPI,
  useStreamSessionElapsedTimeMs,
} from '../../components/Session';
import { useIsStageInited, useStageControlAPI } from '../../components/Stage';
import {
  StreamingActiveMode,
  useStreamingMode,
} from '../../components/StreamingTools/hooks';
import {
  useTeamRandomizerAPI,
  useTeamRandomizerTask,
} from '../../components/TeamRandomizer';
import { useTeamSizeContext } from '../../components/TeamSizeControl/Context';
import { useTownhallAPI } from '../../components/Townhall';
import { useUserContext } from '../../components/UserContext';
import { useMyClientId } from '../../components/Venue/VenuePlaygroundProvider';
import {
  useLocalVideoEffectsSettings,
  useLocalVideoEffectsSettingsStore,
} from '../../components/VideoEffectsSettings/LocalSettings';
import { VideoEffectsSettingsUtils } from '../../components/VideoEffectsSettings/VideoEffectsSettingsUtils';
import {
  useIsCoreChannelJoined,
  useMustCameraVMMediaDeviceRTCService,
  useRTCService,
} from '../../components/WebRTC';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useMyInstance } from '../../hooks/useMyInstance';
import {
  type CameraVMMediaCtrl,
  type CameraVMMediaDeviceRTCService,
  type IMediaDeviceRTCService,
} from '../../services/webrtc';
import { SessionMode } from '../../types/session';
import { assertExhaustive, err2s, uuidv4 } from '../../utils/common';
import { TimeUtils } from '../../utils/time';

const StreamElapsedTime = (): JSX.Element => {
  const elapsedTime = useStreamSessionElapsedTimeMs();
  return (
    <span className='m-1 tabular-nums'>
      {TimeUtils.DurationFormattedHHMMSS(elapsedTime)}
    </span>
  );
};

const EndStreamModal = (props: {
  handleCancel: () => void;
  handleConfirm: () => void;
}): JSX.Element => {
  const {
    state: { transformed: stopState },
    error: stopError,
    call: stop,
    reset: clearStopState,
  } = useAsyncCall(async () => {
    await props.handleConfirm();
  });

  const handleStop = async () => {
    clearStopState();
    await stop();
  };

  return (
    <ModalWrapper containerClassName='w-80' borderStyle='gray'>
      <div className='flex flex-col w-full items-center justify-center px-6 py-8'>
        <header className='text-white text-2xl text-center'>
          Are you sure you want to end the Stream?
        </header>
        <div className='text-white text-sms text-center my-4'>
          Ending your Stream will end the Game and reset all players and scores.
          To go off screen, mute your camera and mic instead.
        </div>
        <div className='flex items-center justify-center'>
          <button
            type='button'
            className='btn-secondary w-30 h-10 mx-1'
            onClick={props.handleCancel}
            disabled={stopState.isRunning}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-delete w-30 h-10 mx-1 flex items-center justify-center'
            onClick={handleStop}
            disabled={stopState.isRunning}
          >
            {stopState.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            <div>Yes</div>
          </button>
        </div>
        {stopError && (
          <div className='text-2xs text-red-002'>{err2s(stopError)}</div>
        )}
      </div>
    </ModalWrapper>
  );
};

type UpdateUserStates = ReturnType<typeof useUserContext>['updateUserStates'];

class CameraVideoMediaCtrl {
  private ctrls = new Map<'intro' | 'outro', CameraVMMediaCtrl | null>();

  private svc: CameraVMMediaDeviceRTCService | null = null;
  private updateUserStates: UpdateUserStates | null = null;

  setDeps(
    svc: CameraVMMediaDeviceRTCService,
    updateUserStates: UpdateUserStates
  ) {
    this.svc = svc;
    this.updateUserStates = updateUserStates;
  }

  destroy() {
    this.ctrls.clear();
  }

  cancelAllPlayingMedia = () => {
    for (const [key, ctrl] of this.ctrls) {
      ctrl?.abort();
      this.ctrls.delete(key);
    }
  };

  waitUntilPlayingComplete = (kind: 'intro' | 'outro') => {
    return this.ctrls.get(kind)?.complete;
  };

  triggerMedia = async (kind: 'intro' | 'outro', media: MediaFormat) => {
    const camera = this.svc?.cameraVideoMixer;
    if (!camera) return;
    const ctrl = await (kind === 'intro'
      ? camera.playIntro(media)
      : camera.playOutro(media));
    this.ctrls.set(kind, ctrl);

    if (ctrl) {
      // Auto mute host mic and monitor (playback locally) music channel while
      // media is playing.
      this.updateUserStates?.({ micOpen: false });
      this.svc?.audioBus.toggleMusicMonitor(true);
      ctrl.complete.finally(() => {
        this.ctrls.delete(kind);
        // Only unmute microphone if no medias are still playing
        if (!Array.from(this.ctrls.values()).some((v) => v)) {
          this.updateUserStates?.({ micOpen: true });
          this.svc?.audioBus.toggleMusicMonitor(false);
        }
      });
    }
  };
}

function useCameraVideoMediaCtrl(svc: CameraVMMediaDeviceRTCService) {
  const [ctrl] = useState(() => new CameraVideoMediaCtrl());
  const userCtx = useUserContext();

  useEffect(() => {
    ctrl.setDeps(svc, userCtx.updateUserStates);
  }, [ctrl, svc, userCtx.updateUserStates]);

  useEffect(() => {
    return () => {
      ctrl.destroy();
    };
  }, [ctrl]);

  return ctrl;
}

type RandomizerSettings = {
  teamSize: number;
  iceBreakerSec: number;
};

function useTeamSizeOptions(val: number): [Option<number>[], Option<number>] {
  const options = useInstance(() =>
    [0, 2, 3, 4, 5, 6, 7, 8].map((v, _) => ({
      label: `${v === 0 ? `Don't Randomize` : v}`,
      value: v,
    }))
  );
  const active = options.find((o) => o.value === val) ?? options[0];
  return [options, active];
}

function useIceBreakerOptions(val: number): [Option<number>[], Option<number>] {
  const options = useInstance(() =>
    [0, 10, 20, 30, 40, 60, 120, 180, 240, 300].map((v, _) => ({
      label: `${
        v === 0 ? 'No Icebreaker' : TimeUtils.AutoFormatMMSS(v, 'long', true)
      }`,
      value: v,
    }))
  );
  const active = options.find((o) => o.value === val) ?? options[0];
  return [options, active];
}

function ConfigurationPanel(props: {
  open: boolean;
  randomizerSettings: RandomizerSettings;
  setRandomizerSettings: React.Dispatch<
    React.SetStateAction<RandomizerSettings>
  >;
}): JSX.Element | null {
  const { open, randomizerSettings, setRandomizerSettings } = props;
  const ves = useLocalVideoEffectsSettings();
  const vesStore = useLocalVideoEffectsSettingsStore();
  const isSessionAlive = useIsStreamSessionAlive();
  const streamingMode = useStreamingMode();
  const liveMode = streamingMode === StreamingActiveMode.Live;
  const [teamSizeOptions, activeTeamSizeOption] = useTeamSizeOptions(
    randomizerSettings.teamSize
  );
  const [iceBreakerOptions, activeIceBreakerOption] = useIceBreakerOptions(
    randomizerSettings.iceBreakerSec
  );

  const summary = useMemo(() => {
    const items: string[] = [];
    items.push(`${ves.intro?.enabled ? 'Show' : 'Hide'} Intro`);
    items.push(`${ves.outro?.enabled ? 'Show' : 'Hide'} Outro`);
    if (liveMode) {
      items.push(`Team Size: ${randomizerSettings.teamSize || 'N/A'}`);
      items.push(
        `Ice Breaker: ${TimeUtils.AutoFormatMMSS(
          randomizerSettings.iceBreakerSec
        )}`
      );
    }
    return items.join(', ');
  }, [
    randomizerSettings.iceBreakerSec,
    randomizerSettings.teamSize,
    ves.intro?.enabled,
    ves.outro?.enabled,
    liveMode,
  ]);

  if (!open) {
    if (isSessionAlive) return null;
    return <div className='text-icon-gray mt-2 text-2xs'>{summary}</div>;
  }

  const introDisabled = !ves.intro || isSessionAlive;
  const outroDisabled = !ves.outro;

  return (
    <div className='w-full text-2xs text-white flex flex-col gap-4 my-4 z-5'>
      <label
        className={`flex items-center font-medium ${
          introDisabled ? 'text-secondary cursor-not-allowed' : ''
        }`}
      >
        <input
          type='checkbox'
          className='checkbox-dark'
          checked={ves.intro?.enabled}
          onChange={(ev) => vesStore.toggleEffect('intro', ev.target.checked)}
          disabled={introDisabled}
        />
        <div className='ml-1'>
          {!!ves.intro ? 'Show INTRO video before game' : 'INTRO not selected'}
        </div>
      </label>
      <label
        className={`flex items-center font-medium ${
          outroDisabled ? 'text-secondary cursor-not-allowed' : ''
        }`}
      >
        <input
          type='checkbox'
          className='checkbox-dark'
          checked={ves.outro?.enabled}
          onChange={(ev) => vesStore.toggleEffect('outro', ev.target.checked)}
          disabled={outroDisabled}
        />
        <div className='ml-1'>
          {!!ves.outro ? 'Show OUTRO video after game' : 'OUTRO not selected'}
        </div>
      </label>
      <label
        className={`flex flex-col font-bold ${
          isSessionAlive ? 'text-secondary cursor-not-allowed' : ''
        }`}
      >
        <div className='mb-2'>Randomization Target Team Size</div>
        <Select<number>
          options={teamSizeOptions}
          onChange={(o) =>
            setRandomizerSettings((prev) => ({ ...prev, teamSize: o.value }))
          }
          value={activeTeamSizeOption}
          disabled={isSessionAlive}
          justifyContent='justify-between'
          fontSize='text-2xs'
        />
      </label>
      <label
        className={`flex flex-col font-bold ${
          isSessionAlive ? 'text-secondary cursor-not-allowed' : ''
        }`}
      >
        <div className='mb-2'>Ice Breaker Timer</div>
        <Select<number>
          options={iceBreakerOptions}
          onChange={(o) =>
            setRandomizerSettings((prev) => ({
              ...prev,
              iceBreakerSec: o.value,
            }))
          }
          value={activeIceBreakerOption}
          disabled={isSessionAlive}
          justifyContent='justify-between'
          fontSize='text-2xs'
        />
      </label>
    </div>
  );
}

function usePublishStream(rtc: IMediaDeviceRTCService) {
  const myClientId = useMyClientId();
  const stageControl = useStageControlAPI();

  const networkQualityCallback = useCallback(
    (stats: NetworkQuality) => {
      stageControl.updateNetworkQuaility(
        myClientId,
        stats.uplinkNetworkQuality,
        stats.downlinkNetworkQuality
      );
    },
    [myClientId, stageControl]
  );

  useEffect(() => {
    return () => {
      rtc.off('network-quality', networkQualityCallback);
    };
  }, [rtc, networkQualityCallback]);

  return {
    publishStream: async () => {
      rtc.on('network-quality', networkQualityCallback);
      try {
        await rtc.publish();
      } catch (error) {
        rtc.log.error('publish failed', err2s(error));
        throw error;
      }
    },
    unpublishStream: async () => {
      rtc.off('network-quality', networkQualityCallback);
      try {
        await rtc.unpublish();
      } catch (error) {
        rtc.log.error('unpublish failed', err2s(error));
        throw error;
      }
    },
  };
}

type State = 'default' | 'configuring' | 'loading' | 'running' | 'aborted';

export const StreamControl = (props: { zIndex: string }): JSX.Element => {
  const joined = useIsCoreChannelJoined(['stage', 'game']);
  const myClientId = useMyClientId();
  const rtcService = useMustCameraVMMediaDeviceRTCService(
    useRTCService('stage')
  );
  const isSessionInited = useIsStreamSessionInited();
  const isStageInited = useIsStageInited();
  const isSessionAlive = useIsStreamSessionAlive();
  const isSessionAborted = useIsStreamSessionAborted();
  const participant = useMyInstance();
  const isNewStart = useIsStreamStartable();
  const {
    prepare: prepareStreamSession,
    start: startStreamSession,
    stop: stopStreamSession,
    abort: abortStreamSession,
    resume: resumeStreamSession,
  } = useStreamSessionControlAPI();
  const toggleBroadcastFeature = useToggleBroadcastFeature();
  const stageControl = useStageControlAPI();
  const expScore = useExperienceScoreAPI();
  const vesStore = useLocalVideoEffectsSettingsStore();
  const streamingMode = useStreamingMode();
  const liveMode = streamingMode === StreamingActiveMode.Live;

  const autoPlayMusic = streamingMode !== StreamingActiveMode.Rec;
  const { play, pause } = useMusicPlayerContext();
  const configurable = streamingMode !== StreamingActiveMode.Rec;
  const [openConfigurationPanel, setOpenConfigurationPanel] = useState(false);
  const [randomizerSettings, setRandomizerSettings] =
    useState<RandomizerSettings>({
      teamSize: 4,
      iceBreakerSec: 30,
    });
  const randomizerAPI = useTeamRandomizerAPI();
  const lobbyAPI = useLobbyAPI();
  const randomizing = !!useTeamRandomizerTask()?.id;
  const numOfParticipants = useNumOfParticipants({
    filters: ['staff:false', 'status:connected', 'host:false'],
  });
  const townhallAPI = useTownhallAPI();
  const { updateMaxMembers } = useTeamSizeContext();

  const ves = useLocalVideoEffectsSettings();
  // HACK: valtio needs to know we depend on the values of ves, but we don't
  // have any explicit dependencies. we're using a spread operator here to
  // indicate we care about all changes. this is somewhat contrary to what the
  // docs say:
  // https://github.com/pmndrs/valtio/wiki/Some-gotchas#usesnapshotstate-without-property-access-will-always-trigger-re-render
  // but in practice, it's not working as described. this const and the rhs are
  // important for updating the stream control preview when the settings change
  // elsewhere.
  const hasVideoEffectsEnabled = VideoEffectsSettingsUtils.HasAnyEnabled({
    ...ves,
  });

  const { cancelAllPlayingMedia, triggerMedia, waitUntilPlayingComplete } =
    useCameraVideoMediaCtrl(rtcService);
  const userCtx = useUserContext();

  const maybeTriggerPlayMedia = async (kind: 'intro' | 'outro') => {
    const media =
      kind === 'intro'
        ? vesStore.settings.intro?.config.mediaFormat
        : vesStore.settings.outro?.config.mediaFormat;

    if (
      (kind === 'intro' && (!isNewStart || !ves.intro?.enabled)) ||
      (kind === 'outro' && (isSessionAborted || !ves.outro?.enabled)) ||
      !media
    )
      return;

    // Automatically turn the host's audio broadcast state on. triggerMedia will
    // mute the host's microphone separately. The host's audio must be on in
    // order for the audience to hear the intro video audio.
    userCtx.toggleAudio(true);
    return await triggerMedia(kind, media);
  };

  // These are stopped as part of the fade out.
  const restartHostStagePodium = async () => {
    await rtcService.cameraVideoMixer?.forceRebuildPersistentTracks();
  };

  useEffect(() => {
    // Enable or disable the cameraVideoMixer for the host, and glue external
    // settings if necessary.
    // Only enable the cameraVideoMixer when absolutely necessary: greenscreen,
    // intro/outro, podium. Anytime mixing or effects are actually needed.
    if (streamingMode === StreamingActiveMode.Live && hasVideoEffectsEnabled) {
      rtcService.enableCameraVideoMixer();
    } else {
      rtcService.disableCameraVideoMixer();
    }

    rtcService.cameraVideoMixer?.updateVideoEffectsConfig(ves);
  }, [rtcService, streamingMode, ves, hasVideoEffectsEnabled]);

  const isLiveGamePlay = useIsLiveGamePlay();
  const { publishStream, unpublishStream } = usePublishStream(rtcService);

  useEffect(() => {
    return () => {
      abortStreamSession(false);
      stageControl.leave(myClientId);
    };
  }, [abortStreamSession, stageControl, myClientId]);

  useEffect(() => {
    // Clear randomizer if streaming mode is changed to non-live.
    if (streamingMode === StreamingActiveMode.Live) return;
    randomizerAPI.cleanup();
    setOpenConfigurationPanel(false);
  }, [randomizerAPI, streamingMode]);

  const {
    state: { transformed: startState },
    error: startError,
    call: startStream,
    reset: clearStartState,
  } = useAsyncCall(async () => {
    await toggleBroadcastFeature(false);
    await maybeTriggerPlayMedia('intro');
    await publishStream();
    await prepareStreamSession(uuidv4());
    await startStreamSession(SessionMode.Live);
    await stageControl.join(myClientId);
  });

  const [raondomizerReady, setRandomizerReady] = useState(false);

  const asyncStartRandomizer = useAsyncCall(async () => {
    await lobbyAPI.hide(true);
    const finalizer = await randomizerAPI.randomize({
      step: 'randomize',
      targetTeamSize: randomizerSettings.teamSize,
      maxTeamSize: randomizerSettings.teamSize + 1,
      countdownSec: 0,
      icebreakerSec: randomizerSettings.iceBreakerSec,
      notificationStyle: 'switch-notice',
      showIcebreakerTimer: false,
      showResults: false,
      showAnimation: true,
    });
    setRandomizerReady(true);
    await lobbyAPI.hide(false);
    await updateMaxMembers(randomizerSettings.teamSize + 1);
    await townhallAPI.setNext({
      mode: 'team',
      countdownSec: 0,
      type: 'global',
      source: 'stream-control',
    });
    return await finalizer(false);
  });

  async function handleStart() {
    clearStartState();
    cancelAllPlayingMedia();
    setOpenConfigurationPanel(false);

    let doRandomizer = false;
    const numOfMinPlayers = getFeatureQueryParamNumber(
      'team-raondomizer-min-players'
    );
    try {
      if (
        randomizerSettings.teamSize > 0 &&
        numOfParticipants >= numOfMinPlayers &&
        liveMode
      ) {
        doRandomizer = true;
        const finishState = await asyncStartRandomizer.call();
        if (finishState === 'aborted') {
          setRandomizerReady(false);
          return;
        }
      }
      await startStream();
      if (doRandomizer) {
        await randomizerAPI.cleanup();
        setRandomizerReady(false);
      }
    } catch (error) {
      throw error;
    } finally {
      if (doRandomizer) await lobbyAPI.hide(false);
    }

    if (autoPlayMusic) {
      await waitUntilPlayingComplete('intro');
      play('stream control autoplay');
    }
  }

  const {
    state: { transformed: resumeState },
    error: resumeError,
    call: resumeStream,
    reset: clearResumeState,
  } = useAsyncCall(async () => {
    await toggleBroadcastFeature(false);
    await publishStream();
    await resumeStreamSession();
    await stageControl.join(myClientId);
  });

  async function handleResume() {
    clearResumeState();
    cancelAllPlayingMedia();
    setOpenConfigurationPanel(false);
    await resumeStream();
  }

  async function handleStop() {
    cancelAllPlayingMedia();
    setOpenConfigurationPanel(false);
    if (autoPlayMusic) pause('stream control autoplay');
    await maybeTriggerPlayMedia('outro');
    await waitUntilPlayingComplete('outro');
    expScore.emitLog();
    expScore.reset();
    await unpublishStream();
    await stopStreamSession();
    await reset();
    await restartHostStagePodium();
    await stageControl.leave(myClientId);
    const forceMode = getFeatureQueryParamArray('townhall-force-mode');
    if (forceMode === 'team') return;
    await townhallAPI.setNext({
      mode: 'crowd',
      countdownSec: 0,
      type: 'global',
      source: 'stream-control',
    });
  }

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const handleEnd = () => {
    triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: (p) => (
        <EndStreamModal
          handleConfirm={async () => {
            await handleStop();
            p.internalOnConfirm();
          }}
          handleCancel={p.internalOnConfirm}
        />
      ),
    });
  };

  const asyncStopRandomizer = useAsyncCall(async () => {
    await randomizerAPI.cleanup();
  });

  const handleStopRandomizer = async () => {
    asyncStopRandomizer.reset();
    await asyncStopRandomizer.call();
    const forceMode = getFeatureQueryParamArray('townhall-force-mode');
    if (forceMode === 'team') return;
    await townhallAPI.setNext({
      mode: 'crowd',
      countdownSec: 0,
      type: 'global',
      source: 'stream-control',
    });
  };

  const showLoading =
    startState.isRunning ||
    resumeState.isRunning ||
    asyncStartRandomizer.state.transformed.isRunning ||
    asyncStopRandomizer.state.transformed.isRunning;

  useHotkeys(
    'ctrl+shift+z',
    () => {
      handleStop();
    },
    {
      enabled: isSessionAlive || isSessionAborted,
    }
  );

  const preparing =
    !isSessionInited || !isStageInited || !participant || !joined;

  const state = useMemo<State>(() => {
    if (randomizing) return 'configuring';
    if (showLoading) return 'loading';
    if (isSessionAlive) return 'running';
    if (isSessionAborted) return 'aborted';
    return 'default';
  }, [isSessionAborted, isSessionAlive, randomizing, showLoading]);

  let action: JSX.Element | null = null;
  switch (state) {
    case 'default':
      action = (
        <>
          <button
            id='host-start-stream'
            type='button'
            className='btn-primary h-10 w-40 flex-col items-center justify-center'
            onClick={handleStart}
          >
            <div className='h-full flex items-center justify-center'>
              <PlayIcon className='w-4 h-4 mr-1' />
              <span>Start Stream</span>
            </div>
            {startError && (
              <div className=' text-2xs text-red-002'>{err2s(startError)}</div>
            )}
          </button>
          {configurable && (
            <button
              type='button'
              className='text-white btn'
              onClick={() => setOpenConfigurationPanel(!openConfigurationPanel)}
            >
              {openConfigurationPanel ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </button>
          )}
        </>
      );
      break;
    case 'configuring':
      action = (
        <button
          id='host-end-stream'
          type='button'
          className='btn-delete h-8 px-8 flex items-center justify-center rounded-2xl'
          onClick={handleStopRandomizer}
          disabled={!raondomizerReady}
        >
          <span>Stop Randomizer</span>
        </button>
      );
      break;
    case 'loading':
      action = (
        <div className='relative flex items-center justify-center'>
          <button
            type='button'
            className='btn-primary h-10 w-40 flex items-center justify-center bg-opacity-40'
            disabled={true}
          ></button>
          <div className='absolute flex items-center justify-center text-white'>
            <img src={spinner} alt='spinner' className='w-5 h-5 mr-2' />
            <span>Loading...</span>
          </div>
        </div>
      );
      break;
    case 'running':
      action = (
        <>
          <button
            id='host-end-stream'
            type='button'
            className='btn-delete h-8 px-8 flex items-center justify-center rounded-2xl'
            onClick={handleEnd}
          >
            <span>End Stream</span>
            <StreamElapsedTime />
          </button>
          {configurable && (
            <button
              type='button'
              className='text-white'
              onClick={() => setOpenConfigurationPanel(!openConfigurationPanel)}
            >
              <ArrowDownIcon />
            </button>
          )}
        </>
      );
      break;
    case 'aborted':
      action = (
        <>
          <button
            id='host-resume-stream'
            type='button'
            className='btn-primary h-8 px-8 flex-col items-center justify-center rounded-2xl ml-2'
            onClick={handleResume}
          >
            <div className='h-full flex items-center justify-center'>
              <span>Resume Stream</span>
              <StreamElapsedTime />
            </div>
            {resumeError && (
              <div className='text-2xs text-red-002'>{err2s(resumeError)}</div>
            )}
          </button>
          <button
            id='host-end-stream'
            type='button'
            className='btn-delete h-8 px-8 flex items-center justify-center rounded-2xl ml-2'
            onClick={handleEnd}
          >
            <span>End Stream</span>
          </button>
        </>
      );
      break;
    default:
      assertExhaustive(state);
      break;
  }

  return (
    <div
      className={`${
        isLiveGamePlay ? 'flex' : 'hidden'
      } w-auto h-auto absolute flex-col items-center justify-center 
      ${props.zIndex} ${state === 'running' ? 'top-1' : 'top-6'} 
      left-1/2 transform -translate-x-1/2 transition-position
      rounded-xl px-6 py-2 bg-black bg-opacity-80`}
    >
      <div className='relative'>
        {preparing ? (
          <div className='text-white'>Getting Ready...</div>
        ) : (
          <div className={`flex flex-col items-center justify-center z-5`}>
            <div className='flex flex-row gap-2 w-full justify-center items-center pt-1'>
              {state !== 'running' && (
                <DupHostStreamView containerClassName='relative w-10 h-10 rounded-lg overflow-hidden' />
              )}
              {action}
            </div>
          </div>
        )}
        <LayoutAnchor
          id='lobby-top-spacing-anchor'
          className='w-full h-15 absolute z-0'
        />
      </div>
      <ConfigurationPanel
        open={openConfigurationPanel}
        randomizerSettings={randomizerSettings}
        setRandomizerSettings={setRandomizerSettings}
      />
    </div>
  );
};
